import { useCallback, useMemo, useState } from 'react'

import { ReactComponent as Exclamation } from 'assets/svg/exclamationWarning.svg'
import { ReactComponent as StopActionIcon } from 'assets/svg/stopAction.svg'
import { ReactComponent as StartActionIcon } from 'assets/svg/startAction.svg'
import {
  Droplist,
  Icon,
  IconWithTooltip,
  ProtectedButton,
  TabBar,
  Text,
} from 'components'

import styles from './CentralInfo.module.scss'

import { usePermissions, useToggle } from 'shared/hooks'
import { CentralCardFooter } from 'domains/customer/screens/Equipments/components/CentralCardFooter/CentralCardFooter'
import { CentralTypeChange } from 'domains/customer/screens/Equipments/components/CentralTypeChange/CentralTypeChange'
import { EquipmentPhoto } from 'domains/customer/screens/Equipments/components/EquipmentPhoto/EquipmentPhoto'
import { CentralReboot } from 'domains/customer/screens/Equipments/components/CentralReboot/CentralReboot'
import { CentralConfigureSafeCall } from 'domains/customer/screens/Equipments/components/CentralConfigureSafeCall/CentralConfigureSafeCall'
import { CentralWifiConfiguration } from 'domains/customer/screens/Equipments/components/CentralWifiConfiguration/CentralWifiConfiguration'
import { UbideskPermissions } from 'routes/types'
import { CENTRAL_MODE, CentralAggregatedResponse } from 'services/central/types'
import { CentralMaintenanceModal } from '../CentralMaintenanceModal/CentralMaintenanceModal'
import { ReactComponent as Option } from 'assets/svg/option.svg'
import { ReactComponent as Pen } from 'assets/svg/listEditIcon.svg'
import { useNavigate } from 'react-router-dom'
import { PartitionsCardFooter } from '../PartitionsCardFooter'
import { AccountTabs } from 'domains/customer/screens/Central/Central'
import {
  ActivateCentralModal,
  DeactivateCentralModal,
} from 'domains/customer/components/CentralForm/components'
import Button from 'components/ButtonV2'
import { useCustomerContext } from 'domains/customer/screens/CustomerManagementTabs/CustomerProvider'
import { buildPath, paths } from 'routes'

export interface CentralCardProps {
  isVisible: boolean
  onClose(): void
  central: CentralAggregatedResponse
  lastFetchDate: string
  refetch: () => Promise<unknown>
  accountsIds: string[]
  tab: {
    name: AccountTabs
    setActiveTab: (data: AccountTabs) => void
  }
}

export const CentralInfo = (props: CentralCardProps) => {
  const { account } = useCustomerContext()
  const { central, isVisible, lastFetchDate, refetch, tab, accountsIds } = props

  const centralTypeChangeModal = useToggle()
  const centralRebootModal = useToggle()
  const centralEnableDigitModal = useToggle()
  const centralWifiConfigurationModal = useToggle()
  const deactivateCentralToggle = useToggle()
  const activateCentralToggle = useToggle()
  const navigate = useNavigate()
  const { hasAccess } = usePermissions()

  const [shouldSetCentralToDemoType, setShouldSetCentralToDemoType] =
    useState(false)

  const centralMaintenanceModalComponent = useToggle()

  function handleChangeCentralType(demo: boolean) {
    setShouldSetCentralToDemoType(demo)
    centralTypeChangeModal.show()
  }

  const handleModalMaintenanceMode = useCallback(() => {
    centralMaintenanceModalComponent.show()
  }, [centralMaintenanceModalComponent])

  const centralIsInMaintenanceMode = useMemo(
    () => central.mode === CENTRAL_MODE.MAINTENANCE,
    [central],
  )

  const hasPermission = hasAccess(UbideskPermissions.EQUIPMENTS_WRITE)

  if (!isVisible) return <></>

  return (
    <div className={styles.cardContainer} aria-label="equipment-card-details">
      <CentralMaintenanceModal
        isVisible={centralMaintenanceModalComponent.isVisible}
        onHandleCloseModal={centralMaintenanceModalComponent.hide}
        central={central}
        partitions={central.partitions}
      />
      <CentralTypeChange
        isVisible={centralTypeChangeModal.isVisible}
        onClose={centralTypeChangeModal.hide}
        onClick={centralTypeChangeModal.hide}
        central={{
          id: central.id,
          demo: shouldSetCentralToDemoType,
        }}
      />
      <CentralReboot
        isVisible={centralRebootModal.isVisible}
        onClose={centralRebootModal.hide}
        onClick={centralRebootModal.hide}
        central={{
          id: central.id,
          connected: central.connected,
        }}
      />
      <CentralConfigureSafeCall
        isVisible={centralEnableDigitModal.isVisible}
        onClose={centralEnableDigitModal.hide}
        central={{
          id: central.id,
          safeCall: central.safeCall,
        }}
      />
      {!!central && (
        <DeactivateCentralModal
          centralId={central.id}
          onClose={deactivateCentralToggle.hide}
          isOpen={deactivateCentralToggle.isVisible}
        />
      )}
      {!!central && (
        <ActivateCentralModal
          centralId={central.id}
          onClose={activateCentralToggle.hide}
          isOpen={activateCentralToggle.isVisible}
        />
      )}
      {central.wifi && (
        <CentralWifiConfiguration
          isVisible={centralWifiConfigurationModal.isVisible}
          onClose={centralWifiConfigurationModal.hide}
          central={{
            id: central.id,
            wifiIpMode: central.wifi?.ipMode,
          }}
        />
      )}
      <div className={styles.header}>
        <div className={styles.informationHeader}>
          <div className={styles.cardTitleContainer}>
            <div>
              <div className={styles.cardTitle}>
                <Text as="h2" size="3xl" weight="bold">
                  {central.aggregatedAccountName}
                </Text>
                {central.isUbisafe && (
                  <p className={styles.demo}>
                    {central.demo ? 'Demo' : 'Cliente'}
                  </p>
                )}
                {central.tamper && (
                  <IconWithTooltip text="Tamper aberto" Icon={Exclamation} />
                )}
              </div>

              <div className={styles.cardTitle}>
                <h3>
                  {central.serviceType?.name} • Central{' '}
                  {central.manufacturer?.name}
                </h3>
              </div>
            </div>
            {central.isUbisafe ? (
              <div>
                <Droplist
                  trigger={
                    <div className={styles.options}>
                      <Option aria-label="options" />
                    </div>
                  }
                >
                  <div className={styles.dropListWrapper}>
                    <button
                      onClick={() =>
                        navigate(
                          buildPath({
                            path: paths.account.central.update,
                            params: {
                              accountId: account?.id,
                              centralId: central.id,
                            },
                          }),
                        )
                      }
                    >
                      Editar
                    </button>
                    <button
                      className={[!hasPermission && styles.disabled]
                        .filter(Boolean)
                        .join(' ')}
                      onClick={centralRebootModal.show}
                      disabled={!hasPermission}
                    >
                      Reiniciar central
                    </button>
                    <button onClick={centralEnableDigitModal.show}>
                      Habilitar dígito 190
                    </button>
                    <button
                      className={[
                        !hasPermission ||
                          (central.mode !== CENTRAL_MODE.MAINTENANCE &&
                            styles.disabled),
                      ]
                        .filter(Boolean)
                        .join(' ')}
                      disabled={
                        !hasPermission ||
                        central.mode !== CENTRAL_MODE.MAINTENANCE
                      }
                      onClick={centralWifiConfigurationModal.show}
                    >
                      Alterar Wi-Fi
                    </button>
                    <hr />
                    <button
                      className={[
                        !hasPermission ||
                          (central.mode !== CENTRAL_MODE.MAINTENANCE &&
                            styles.disabled),
                      ]
                        .filter(Boolean)
                        .join(' ')}
                      onClick={() => handleChangeCentralType(!!central.demo)}
                      disabled={
                        !hasPermission ||
                        central.mode !== CENTRAL_MODE.MAINTENANCE
                      }
                    >
                      {central.demo
                        ? "Alterar para tipo 'Cliente'"
                        : "Alterar para tipo 'Demo'"}
                    </button>
                    <button
                      className={[!hasPermission && styles.disabled]
                        .filter(Boolean)
                        .join(' ')}
                      onClick={
                        central.active
                          ? deactivateCentralToggle.show
                          : activateCentralToggle.show
                      }
                      disabled={!hasPermission}
                    >
                      {central.active ? 'Desativar' : 'Reativar'}
                    </button>
                  </div>
                </Droplist>
              </div>
            ) : (
              <button
                className={styles.editCentral}
                onClick={() => {
                  navigate(
                    buildPath({
                      path: paths.account.central.update,
                      params: { accountId: account?.id, centralId: central.id },
                    }),
                  )
                }}
              >
                <Pen /> Editar
              </button>
            )}
          </div>
        </div>
        {central.isUbisafe && (
          <div className={styles.maintenanceButton}>
            {centralIsInMaintenanceMode ? (
              <ProtectedButton
                permissionName={UbideskPermissions.EQUIPMENTS_WRITE}
                id="btn-maintenance-mode"
                type="cancel-secondary"
                buttonTitle="Parar manutenção"
                onClick={handleModalMaintenanceMode}
                className={styles.buttonMaintenance}
                icon={StopActionIcon}
              />
            ) : (
              <ProtectedButton
                permissionName={UbideskPermissions.EQUIPMENTS_WRITE}
                id="btn-default-mode"
                type="secondary"
                buttonTitle="Iniciar manutenção"
                onClick={handleModalMaintenanceMode}
                className={styles.buttonMaintenance}
                icon={StartActionIcon}
              />
            )}
          </div>
        )}
      </div>
      <TabBar>
        <TabBar.Tab
          id={'CENTRAL'}
          idSelected={tab.name}
          label="Dados gerais"
          onClick={() => tab.setActiveTab('CENTRAL')}
        >
          <div className={styles.centralInfo}>
            {!central.active && (
              <span>
                <div>
                  <Icon name="warning" width={18} />
                  Central desativada
                </div>

                <Button
                  size="md"
                  color="red"
                  appearance="tertiary"
                  onClick={activateCentralToggle.show}
                >
                  REATIVAR
                </Button>
              </span>
            )}
            <EquipmentPhoto
              imageId={central.installationImageId || ''}
              alt="Módulo"
              active={central.active}
            />

            <div className={styles.footer}>
              <CentralCardFooter
                central={central}
                lastFetchDate={lastFetchDate}
                refetch={refetch}
              />
            </div>
          </div>
        </TabBar.Tab>
        <TabBar.Tab
          id={'PARTITIONS'}
          idSelected={tab.name}
          label="Partições"
          onClick={() => tab.setActiveTab('PARTITIONS')}
        >
          <div className={styles.footer}>
            <PartitionsCardFooter
              central={central}
              accountIds={accountsIds}
              lastFetchDate={lastFetchDate}
              refetch={refetch}
            />
          </div>
        </TabBar.Tab>
      </TabBar>
    </div>
  )
}
