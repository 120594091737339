import React, { useEffect, useMemo, useState } from 'react'

import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'
import { useGetAccounts } from 'shared/hooks/accounts/useGetAccounts'

import { Button, EmptyState, LoaderV2 } from 'components'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { ReactComponent as PlusIcon } from 'assets/svg/plusSign.svg'
import { useQueryCentralByAccounts } from 'services/central'

import { CentralAggregatedResponse } from 'services/central/types'
import { CentralInfo } from '../Equipments/components/CentralInfo/CentralInfo'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import styles from './Central.module.scss'
import { dateNow } from 'utilities/date'
import { CentralCard } from './CentralCard/CentralCard'
import { useGetPatrimonyFragment } from 'shared/hooks'
import { PatrimonyFragment } from 'services/patrimony/types'
import { useGetPatrimony } from 'services/patrimony/hooks/useGetPatrimony'
import { useCustomerContext } from '../CustomerManagementTabs/CustomerProvider'
import { handleRenderState } from 'utilities/handleRenderState'
import { buildPath, paths } from 'routes'

const DEFAULT_ACCOUNT_NAME = 'Todas'

const RECORDS_PER_PAGE = 100

type AccountFragment = {
  id: string
  aggregatedAccountName: string
}

const viewAllAccounts = {
  id: '',
  aggregatedAccountName: DEFAULT_ACCOUNT_NAME,
}

const accountTabs = {
  CENTRAL: 'central',
  PARTITIONS: 'partitions',
}

export type AccountTabs = keyof typeof accountTabs

const Central: React.FC = () => {
  const {
    patrimony: contextPatrimony,
    customer: contextCustomer,
    account: contextAccount,
  } = useCustomerContext()
  const navigate = useNavigate()
  const location = useLocation()

  const [searchParams] = useSearchParams()

  const [selectedPatrimony, setSelectedPatrimony] =
    useState<PatrimonyFragment>()

  const [accountFilter, setAccountFilter] = useState('')
  const [selectedAccount, setSelectedAccount] = useState<
    AccountFragment | undefined
  >(undefined)

  const [selectedCentral, setSelectedCentral] =
    useState<CentralAggregatedResponse>()
  const [activeTab, setActiveTab] = useState<AccountTabs>('CENTRAL')

  const { data: patrimony, isLoading: isLoadingPatrimony } = useGetPatrimony(
    selectedPatrimony?.id || contextPatrimony?.id || '',
  )

  const [patrimonyFragmentName, setPatrimonyFragmentName] = useState('')
  const {
    data: patrimonyFragment,
    isError: isErrorPatrimonyFragment,
    isFetching: isFetchingPatrimonyFragment,
    fetchNextPage: fetchNextPatrimonyPage,
  } = useGetPatrimonyFragment(contextCustomer?.id || '', patrimonyFragmentName)

  const {
    accounts,
    fetchNextAccountsPage,
    isError: isAccountsError,
    isFetching: isAccountsFetching,
  } = useGetAccounts(
    !!selectedPatrimony?.id || !!contextPatrimony?.id,
    contextCustomer?.id,
    {
      name: accountFilter,
    },
    RECORDS_PER_PAGE,
    selectedPatrimony?.id || contextPatrimony?.id,
  )

  const accountsToSearch: string[] = useMemo(
    () => {
      if (!accounts?.length) return []
      if (selectedAccount?.aggregatedAccountName === DEFAULT_ACCOUNT_NAME)
        return accounts.map((account) => account.id)

      if (selectedAccount) {
        return [selectedAccount?.id]
      }

      return []
    },
    [selectedAccount, accounts], // eslint-disable-line
  )

  const {
    data: central,
    isError: isCentralQueryError,
    status: centralQueryStatus,
    isFetching: isCentralFetching,
    refetch,
  } = useQueryCentralByAccounts(
    !selectedAccount && contextAccount?.id
      ? [contextAccount.id]
      : accountsToSearch,
  )

  const isLoading = isCentralFetching || isLoadingPatrimony
  const handleSelectCentral = (central: CentralAggregatedResponse) => {
    if (!selectedCentral) {
      navigate(`?tab=central&centralId=${central.id}`)

      setSelectedCentral(central)
      return
    }

    if (selectedCentral?.id === central.id) {
      navigate({ search: '?tab=central' })

      setSelectedCentral(undefined)
      return
    }

    setSelectedCentral(central)
  }

  const lastDevicesFetchDate = useMemo(() => {
    if (centralQueryStatus === 'success') return dateNow()

    return ''
  }, [centralQueryStatus])

  const centralsGroupedByPatrimony = useMemo(() => {
    const groups: { [key: string]: CentralAggregatedResponse[] } = {}

    if (central && accounts) {
      central.data.forEach((centralItem) => {
        const account = accounts.find(
          (acc) => acc.central?.id === centralItem.id,
        )

        if (account && account.patrimony) {
          const patrimonyName = account.patrimony.name

          if (!groups[patrimonyName]) {
            groups[patrimonyName] = []
          }

          groups[patrimonyName].push({
            ...centralItem,
            aggregatedAccountName: account.aggregatedAccountName,
            serviceType: account.serviceType,
          })
        }
      })
    }

    return groups
  }, [accounts, central])

  useEffect(() => {
    if (selectedCentral && central) {
      const selectedCentralUpdated = central.data.find(
        (device) => device.id === selectedCentral?.id,
      )

      const account = accounts?.find(
        (acc) => acc?.central?.id === selectedCentralUpdated?.id,
      )

      if (selectedCentralUpdated) {
        setSelectedCentral({
          ...selectedCentralUpdated,
          aggregatedAccountName: account?.aggregatedAccountName,
          serviceType: account?.serviceType,
        })
      }
    }
  }, [accounts, central]) //eslint-disable-line

  useEffect(() => {
    if (patrimony && !selectedPatrimony) {
      const formattedPatrimony: PatrimonyFragment = {
        id: patrimony.id,
        name: patrimony.name,
        accountId: contextAccount?.id || '',
        accountCode: '',
      }
      setSelectedPatrimony(formattedPatrimony || patrimonyFragment[0])
    }
  }, [patrimony, contextPatrimony]) //eslint-disable-line

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    const centralId = searchParams.get('centralId')

    if (centralId && !selectedCentral?.id) {
      const pathCentral = centralsGroupedByPatrimony[
        selectedPatrimony?.name || ''
      ]?.find((central) => central.id === centralId)

      if (pathCentral) setSelectedCentral(pathCentral)
    }
  }, [centralsGroupedByPatrimony, selectedCentral, selectedPatrimony]) //eslint-disable-line

  useEffect(() => {
    const param = searchParams.get('openContactModalFrom')

    if (param === 'partition') {
      setActiveTab('PARTITIONS')
    }
  }, [searchParams])

  return (
    <>
      <div className={styles.container}>
        <header className={styles.header}>
          <Combobox
            label={{
              text: 'Patrimônio',
            }}
            value={
              selectedPatrimony
                ? {
                    label: 'name',
                    value: selectedPatrimony,
                  }
                : undefined
            }
            isError={isErrorPatrimonyFragment}
            onEndReached={fetchNextPatrimonyPage}
            onSearch={(search) => setPatrimonyFragmentName(search)}
            isLoading={isFetchingPatrimonyFragment}
            onChange={(selected) => {
              const fragment =
                selected as unknown as ComboboxItem<PatrimonyFragment>
              setSelectedPatrimony(fragment?.value)
              setSelectedAccount(viewAllAccounts)
            }}
            items={parseDataToComboboxV2(patrimonyFragment || [], 'name')}
          />
          <Combobox
            id="account"
            label={{
              text: 'Conta',
            }}
            value={
              selectedAccount
                ? {
                    label: 'aggregatedAccountName',
                    value: selectedAccount,
                  }
                : `${contextAccount?.code} (${contextAccount?.serviceType?.name}) - ${contextAccount?.name}` ||
                  ''
            }
            onSearch={(text) => {
              if (text === '') {
                setSelectedAccount(undefined)
              }

              setAccountFilter(text)
            }}
            items={parseDataToComboboxV2(
              [viewAllAccounts, ...(accounts || [])],
              'aggregatedAccountName',
            )}
            onChange={(selected) => {
              const accountSelected = selected as ComboboxItem<AccountFragment>
              setSelectedAccount(accountSelected.value)
            }}
            isError={isAccountsError}
            isLoading={isAccountsFetching}
            onEndReached={fetchNextAccountsPage}
          />
          <Button
            type="secondary"
            buttonTitle="Nova central"
            width="300px"
            icon={PlusIcon}
            onClick={() =>
              navigate(
                buildPath({
                  path: paths.account.central.create,
                  params: { accountId: contextAccount?.id },
                }),
              )
            }
          />
        </header>
        {
          {
            view: (
              <div className={styles.contentWrapper}>
                <section>
                  {Object.entries(centralsGroupedByPatrimony)
                    .sort((prev, next) =>
                      prev[0].toLowerCase() < next[0].toLowerCase() ? -1 : 1,
                    )
                    .map(([patrimonyName, centrals]) => {
                      return (
                        <div key={patrimonyName}>
                          <h1>{patrimonyName || '-'}</h1>
                          <ul className={styles.content}>
                            {centrals
                              .sort(
                                (prev, next) => next.createdAt - prev.createdAt,
                              )
                              .map((central) => (
                                <CentralCard
                                  key={central.id}
                                  onPress={() => handleSelectCentral(central)}
                                  central={{
                                    ...central,
                                    isConnected: central.connected,
                                  }}
                                />
                              ))}
                          </ul>
                        </div>
                      )
                    })}
                </section>

                {selectedCentral && (
                  <section>
                    <CentralInfo
                      isVisible
                      onClose={() => setSelectedCentral(undefined)}
                      central={selectedCentral}
                      accountsIds={
                        accountsToSearch.length
                          ? accountsToSearch
                          : [contextAccount?.id || '']
                      }
                      lastFetchDate={lastDevicesFetchDate}
                      refetch={refetch}
                      tab={{ name: activeTab, setActiveTab }}
                    />
                  </section>
                )}
              </div>
            ),
            empty: <EmptyState type="EmptyDataFromBFF" />,
            error: <EmptyState type="ErrorToLoadInfos" />,
            loading: (
              <div className={styles.loader}>
                <LoaderV2 />
              </div>
            ),
          }[
            handleRenderState({
              data: central?.data,
              isLoading,
              isError: isCentralQueryError,
              isEmpty: !central?.data.length,
            })
          ]
        }
      </div>
    </>
  )
}

export default Central
